@media screen and (max-width: 600px) {
    .footer {
        display: none;
    }
    
    .scroll-magic {
        display: none;
    }
    
    .line > input {
        width: 16px;
        height: 45px;
    }
    
    .line {
        width: 8px;
    }
    
    .lines.lines-left {
        margin: 0px;
    }
    
    .one-section-content {
        width: 84vw;
    }
    
    .project-section {
        padding: 0 0.5rem 0 1.4rem;
        flex-direction: column;
    }
    
    .info-side {
        padding-right: .375rem;
        width: 100%;
        height: unset;
    }

    .photo-side {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
    }

    .special-phrase {
        display: none;
    }

    .info-side .project-name {
        display: none;
    }

    .info-side > div {
        margin-bottom: 1rem;
    }

    .photo-side .project-name {
        display: flex;
        margin-bottom: .375rem;
    }

    .photo-side > input {
        margin-bottom: 2rem;
    }

    .project-description {
        font-size: 12px;
    }
    
    .project-name {
        font-size: 30px;
    }

    .home-sections {
        align-items: flex-start;
    }

    .home-title {
        height: unset;
        width: 100%;
        padding: 0 2vw;
    }

    .bold-phrase {
        font-size: 1.8rem;
    }

    .menu-houper-box {
        width: 100vw;
    }

    .menu-houper { 
        background: rgba(0,0,0,.9);
    }


    .home-landing-square-rowbox {
        flex-direction: column;
        justify-content: flex-start;
        padding-top: 8rem;
    }
    
    .home-title-box {
        height: unset;
    }
    
    .home-landing-square {
        height: unset;
    }
}