html, body {
    overflow: hidden;
}
.App {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background: black;
}

a {
    text-decoration: none;
    color: inherit;
}

.App-box {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
}

.page {
    display: flex;
    flex: 1;
    max-width: 100vw;
    /* justify-content: center;
    align-items: center; */
}

.design-bg {
    background: conic-gradient(from -11deg at 50% 50%, #FFFFFF 0deg, rgba(255, 255, 255, 0) 360deg);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
    border-radius: 10px;
    box-shadow: 0 5px 10px rgb(180 180 180 / 30%);
}

.menu {
    height: 7.5vh;
    display: flex;
}

.logo-view {
    width: 55px;
}

.menu-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1 1;
    position: fixed;
    left: 0;
    right: 0;
    height: 7.5vh;
    border-bottom: 1px solid #D9D9D9;
    background-color: black;
    z-index: 990;
    padding: 0 2rem;
}

.core {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.core-box {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.core-content {
    display: flex;
    height: 85vh;
    overflow-x: hidden;
    overflow-y: auto;
}
.core-content-box {
    flex: 1;
    display: flex;
}

.home-sections {
    display: flex;
    flex: 1 1;
    justify-content: flex-start;
    align-items: center;
    overflow-y: hidden;
    /* overflow-x: auto; */
    overflow-x: hidden;
    scroll-behavior: smooth;
    position: relative;
}

.scroll-magic {
    position: fixed;
    z-index: 980;
    overflow-y: scroll;
    overflow-x: scroll;
    /* background: rgba(255,0,0,.5); */
    width: 100%;
    top: 7.5vh;
    bottom: 7.5vh;
}

.scroll-magic-box {
    height: 200vh;
}

.footer {
    height: 7.5vh;
    border-top: 1px solid rgba(217,217,217,.2);
    display: flex;
}

.footer-box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding: 0 5.92vw;
}

.contacts-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
}

.one-contact-info {
    display: flex;
    min-width: 20vw;
    display: flex;
    justify-content: center;
}

a.one-contact-link {
    display: flex;
    text-decoration: none;
}

.one-contact-icon {
    margin: 0 .4rem;
}

.one-contact-title {
    font-family: 'Roboto-Bold';
    font-size: 15px;
}

.menu-button-box {
    width: 26px;
}

.photo-side .project-name {
    display: none;
}