/* Inter family fonts */
@font-face {
    font-family: 'Inter';
    src: url(../fonts/Inter/Inter-VariableFont.ttf);
}
@font-face {
    font-family: 'Inter-Light';
    src: url(../fonts/Inter/Inter-Light.ttf);
}
/* Roboto family fonts  */
@font-face {
    font-family: 'Roboto';
    src: url(../fonts/Roboto/Roboto-Regular.ttf);
}
@font-face {
    font-family: 'Roboto-Bold';
    src: url(../fonts/Roboto/Roboto-Bold.ttf);
}
@font-face {
    font-family: 'Roboto-Light';
    src: url(../fonts/Roboto/Roboto-Light.ttf);
}
@font-face {
    font-family: 'Roboto-Medium';
    src: url(../fonts/Roboto/Roboto-Medium.ttf);
}
@font-face {
    font-family: 'Roboto-Thin';
    src: url(../fonts/Roboto/Roboto-Thin.ttf);
}

@font-face {
    font-family: 'Roboto Mono';
    src: url(../fonts/RobotoMono/RobotoMono-Medium.ttf);
}

/* Inter family fonts  */
/* @font-face {
    font-family: SF;
    src: url(../fonts/SF/SFPRODISPLAYREGULAR.OTF);
}

@font-face {
    font-family: SF-Bold;
    src: url(../fonts/SF/SFPRODISPLAYBOLD.OTF);
}

@font-face {
    font-family: SF-Black-Italic;
    src: url(../fonts/SF/SFPRODISPLAYBLACKITALIC.OTF);
}

@font-face {
    font-family: SF-Heavy-Italic;
    src: url(../fonts/SF/SFPRODISPLAYHEAVYITALIC.OTF);
}

@font-face {
    font-family: SF-Light-Italic;
    src: url(../fonts/SF/SFPRODISPLAYLIGHTITALIC.OTF);
}

@font-face {
    font-family: SF-Light;
    src: url(../fonts/SF/SFPRODISPLAYLIGHTITALIC.OTF);
}

@font-face {
    font-family: SF-Medium;
    src: url(../fonts/SF/SFPRODISPLAYMEDIUM.OTF);
}

@font-face {
    font-family: SF-SemiBold;
    src: url(../fonts/SF/SFPRODISPLAYSEMIBOLDITALIC.OTF);
}

@font-face {
    font-family: SF-Thin;
    src: url(../fonts/SF/SFPRODISPLAYTHINITALIC.OTF);
}

@font-face {
    font-family: SF-Ultra-Light;
    src: url(../fonts/SF/SFPRODISPLAYULTRALIGHTITALIC.OTF);
}

@font-face {
    font-family: Raleway;
    src: url(../fonts/Raleway/Raleway.ttf);
}

@font-face {
    font-family: Raleway-Italic;
    src: url(../fonts/Raleway/Raleway-Italic.ttf);
} */
