.menu-houper {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 0;
    overflow: hidden;
    opacity: 0;
    transition: width 0s ease, opacity .5s ease;
    display: flex;
    justify-content: flex-end;
    background: rgba(0,0,0,.25);
    -webkit-backdrop-filter: saturate(180%) blur(10px);
    backdrop-filter: saturate(180%) blur(10px);
    z-index: 999;
}

.menu-houper-close-area {
    flex: 1;
}

.menu-houper.menu-houper-visible-true {
    width: 100vw;
    opacity: 1;
}

.menu-houper-box {
    width: 48vw;
    max-width: 525px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: radial-gradient(50% 50% at 50% 50%, #911F1F 0%, rgba(52, 52, 52, 0) 100%);
}

.menu-item-conteiner {
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 33px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    justify-content: center;
}

.menu-item {
    padding: .75rem 0;
    width: 100%;
}

.menu-items {
    width: 100%;
    height: 27vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.menu-item-title:hover {
    cursor: pointer;
    font-size: 30px;
    transition: all .3s ease;
}