
.home-sections-box {
    min-width: fit-content;
    display: flex;
}

.one-section-view {
    width: 100vw;
}

.one-section-view.homesection {
    width: 90vw;
    display: flex;
    align-items: center;
}

.project-section {
    display: flex;
    justify-content: space-between;
    padding: 0 .75rem;
    box-sizing: border-box;
    flex: 1;
}

.photo-side {
    width: 52vw;
    display: flex;
    align-items: center;
}

.info-side {
    height: 62.76vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 26.78vw;
    padding-right: 1rem;
}

.main-section {
    display: flex;
    box-sizing: border-box;
    height: 100%;
    /* align-items: center; */
}

.one-section-view.default-project {
    /* height: 100vh; */
}

.project-name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 6vh;
    line-height: 59px;
    color: #FFFFFF;
    /* margin: 6vh 0 0 0; */
}

.project-description {
    font-family: 'Inter-Light';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    color: #FFFFFF;
}

.project-destination {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 36px;
    color: #5C6FB4;
    transition: all .3s ease;
}

.project-destination:hover {
    color: #9099b9;
}

.one-section-view.homesection > div {
    align-items: center;
}

.one-section-view.default-project {
    display: flex;
    align-items: center;
}

.one-section-content {
    width: 90vw;
}

.lines {
    display: flex;
    z-index: 990;
}

.line {
    height: 80px;
    width: 1.05vw;
    display: flex;
    align-items: center;
}

.line > input {
    height: 7.86vh;
    width: 2.16vw;
    max-width: unset;
}

.lines.lines-right {
    transform: rotate(180deg);
}

.lines.lines-left {
    margin: 10px;
}

.lines.lines-right {
    margin: 10px;
    box-sizing: border-box;
}

.home-title-box {
    /* height: 23.2vh; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.home-landing {
    width: 100vw;
    /* display: flex; */
    /* align-items: center; */
    /* flex-direction: column; */
}

.home-landing-square {
    height: 85vh;
    display: flex;
}

.home-landing-square-rowbox {
    display: flex;
    align-items: center;
    padding: 0 3rem;
    width: 100vw;
    justify-content: center;
}

.homa-landing-full-image {
    display: flex;
    justify-content: center;
}

.homa-landing-full-image > input {
    max-height: 75vh;
    max-width: unset;
}

.input.input-po input {
    background: none;
    border: none;
    /* height: 50px; */
    margin-bottom: 25px;
    font-size: 20px;
    font-family: 'Roboto';
    border-bottom: 1px solid rgba(255,255,255,.3);
    padding: 0.25rem .5rem;
}

.button.button-type-suit {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
}

.button.button-type-suit .button-title {
    font-size: 25px;
    font-family: 'Roboto-Bold';
}

.input-title {
    font-size: 12px;
    font-family: 'Roboto-Light';
}

*:focus {
    outline: none;
}


.desc-item {
    background: #292929;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 1rem 2rem;
    margin: 20px 0;
}

.desc-item-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 39px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    border-bottom: 1px solid rgba(255,255,255,.2);
}

.desc-item-content {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 27px;
    color: #FFFFFF;
    padding: 0.5rem 0;
}


.home-title.right-title-component {
    width: 100%;
}

.home-title.right-title-component .home-title-box {
    align-items: flex-end;
}


@media screen and (min-width: 601px) {
    .desc-item.titled-desc-item {
        width: 35vw;
    }

    .desc-item.titled-desc-item.item-first {
        margin-right: 37vw;
    }


    .desc-item.titled-desc-item.item-second {
        margin-right: 14rem;
    }

    .right-title-component .text-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: flex-end;
        align-items: flex-end;
    }
}