.special-phrase {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 100%;
    line-height: 24px;
    color: #FFFFFF;
}

.main-phrase {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 1.7rem;
    line-height: 59px;
    color: #FFFFFF;
}

.bold-phrase {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 50px;
    color: #FFFFFF;
}

.home-title {
    padding: 0 6vw 0 6vw;
    /* height: 50vh; */
    /* min-width: 50vw; */
}