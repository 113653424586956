

.button.button-type-suit .button-box {
    background: none;
    border: none;
    /* border-image-source: conic-gradient(from 200.12deg at 50% 50%, #F8F8E5 0deg, rgba(153, 178, 127, 0) 360deg); */
}

.button.button-type-suit.bButton > button {
    font-size: 27px;
    padding: 0;
    font-family: 'Inter';
    font-weight: 600;
    transition: all .3s ease;
}

.button.button-type-suit.bButton:hover > button {
    color: #5cb47a;
}

.button.button-type-none > button {
    background: none;
    border: none;
}

.menu-button-area {
    position: fixed;
    right: 25px;
    width: 2.57vw;
    height: 7.5vh;
    display: flex;
    align-items: center;
}